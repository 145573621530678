import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})


export class ProceedingsService {
  private apiUrl = 'https://api.vpcanabenaulim.in/api/v1/proceedings/'; // Replace with your API URL

  constructor(private http: HttpClient) {
    console.log('inside service constructor');
    
  }

  getProceedingsList(): Observable<any> {
    // Replace 'any' with the appropriate type for your response data
    var status = 'published';

    return this.http.get<any>(this.apiUrl+'listProceedings');
  }
  getProceedingsDetails(id): Observable<any> {
    // Replace 'any' with the appropriate type for your response data
    var status = 'published';

    return this.http.get<any>(this.apiUrl+'getById/'+id);
  }
}
