import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  model = {};

  public name: string;
  public phone: string;
  public email: string;
  public contactFormSubject: string;
  public message: string;

  constructor() { }

  ngOnInit() {
  }

  onSubmit() {
    /*alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))*/
    /*const allInfo = `My name is ${this.name}. My email is ${this.email}. my phone is ${this.phone}. My message is ${this.message}`;*/
    alert('hello');
  }

}
