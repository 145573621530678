import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../shared/services/firestore.service';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { ProceedingsService } from '../../shared/services/proceedings.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-proceedingdetails',
  templateUrl: './proceedingdetails.component.html',
  styleUrls: ['./proceedingdetails.component.css']
})
export class ProceedingdetailsComponent implements OnInit {

  private selectedId : string = '';
  public ProceedingDetails : any;
  public proceeding: any;

  constructor(public firestore: FirestoreService, public router: Router, private route: ActivatedRoute,private proceedingsServices: ProceedingsService,private datePipe: DatePipe) { 
    this.route.queryParams.subscribe(params => {
      this.selectedId = params["id"];
    });

    // this.firestore.getProceeding().subscribe(x =>{
    //   this.ProceedingDetails = x.docs.filter(proceedingList =>{
    //     this.proceeding = proceedingList.data();
    //     if(proceedingList.id == this.selectedId){
    //       console.log(this.proceeding);
    //       return true;
    //     }
    //     else {
    //       return false
    //     };
    //   }).map(proceedinglist =>{          
    //     this.proceeding = proceedinglist.data()
    //     let a = {
    //       'id': proceedinglist.id,
    //         'title' : this.proceeding.EventTitle,
    //         'type' : this.proceeding.EventType,
    //         'subject' : this.proceeding.EventSubject,
    //         'date': this.proceeding.EventDate,
    //         'duration': this.proceeding.EventDuration,
    //         'location' : this.proceeding.EventLocation,
    //         'description' : this.proceeding.EventDescription,
    //         'mom' : this.proceeding.EventMinutes,
    //     }
    //     return a;
    //   });

    //   console.log(this.ProceedingDetails);
      
    // });
  }

  ngOnInit() {

    this.getProceedingDetails(this.selectedId);
  }

  getProceedingDetails(id){

    this.proceedingsServices.getProceedingsDetails(id).subscribe((data)=>{

      this.ProceedingDetails = data;
      console.log(this.ProceedingDetails);
      
    },(error)=>{
      console.error('Error fetching data:', error);
    });
  }
}
