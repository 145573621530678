import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../shared/services/firestore.service';
import { NavigationExtras, Router } from '@angular/router';
import { ProceedingsService } from '../shared/services/proceedings.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-proceedings',
  templateUrl: './proceedings.component.html',
  styleUrls: ['./proceedings.component.css']
})
export class ProceedingsComponent implements OnInit {
  public ProceedingList: any;
  public proceeding: any;
  private responseData: {};
  constructor(public firestore: FirestoreService, public router: Router,private proceedingsServices: ProceedingsService,private datePipe: DatePipe) {
    
    // this.firestore.getProceeding().subscribe(x =>{
    //   this.ProceedingList = x.docs.map(proceedinglist =>{          
    //     this.proceeding = proceedinglist.data()
    //     let a = {
    //       'id': proceedinglist.id,
    //       'title' : this.proceeding.EventTitle,
    //       'type' : this.proceeding.EventType,
    //       'subject' : this.proceeding.EventSubject,
    //       'date': this.proceeding.EventDate,
    //       'duration': this.proceeding.EventDuration,
    //       'location' : this.proceeding.EventLocation,
    //       'description' : this.proceeding.EventDescription,
    //       'mop' : this.proceeding.EventMinutes,
    //     }
    //     return a;
    //   });
    // });
   }

  ngOnInit() {
    this.getProceedingsList();
  }
  
  getProceedingsList(){
    
    this.proceedingsServices.getProceedingsList().subscribe((data)=>{
      this.ProceedingList = data;
      console.log(this.ProceedingList);
      
    },(error)=>{
      console.error('Error fetching data:', error);
    });

  }
  onDisplayDetails(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    }
    this.router.navigate(['/proceeding-details'], navigationExtras)
  }

}

