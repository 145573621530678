import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acts',
  templateUrl: './acts.component.html',
  styleUrls: ['./acts.component.css']
})
export class ActsComponent implements OnInit {

  acts: Act[] = [
    new Act('Citizens Charter','Citizens Charter','The Citizens Charter and Grievance Redressal Bill 2011 also known as The Right of Citizens for Time Bound Delivery of Goods and Services and Redressal of their Grievances Bill, 2011 or Citizens Charter Bill was proposed by Indian central legislation.','lime'),
    new Act('Right to Information Act','Information Act','The Right to Information Act (RTI) is an Act of the Parliament of India “to provide for setting out the practical regime of right to information for citizens” and replaces the erstwhile Freedom of information Act, 2002.','forest'),
    new Act('Panchayat Raj Act','Panchayat Raj Act','The Goa Panchayat Raj Act, 1994 (Goa Act 14 of 1994) which has been passed by the Legislative Assembly of Goa on 25-5-1994 and assented to by the Governor of Goa on 9-7-1994, is hereby published for general information of the public.','green')
  ];

  constructor() { }

  ngOnInit() {
  }

}

export class Act {
  constructor (
    public name: string,
    public docname: string,
    public desc: string,
    public image: string,
    ) {}
}
