import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore) { }

  //Meeting function
  public getMeeting() {
    return this.firestore.collection('Events',ref => ref.where('EventType','==','Meeting')).get();
  }

  //Proceedings function
  public getProceeding() {
    return this.firestore.collection('Events',ref => ref.where('EventType','==','Proceeding')).get();
  }

}