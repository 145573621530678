import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../shared/services/firestore.service';
import { NavigationExtras, Router } from '@angular/router';


@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.css']
})

export class MeetingsComponent implements OnInit {
  public MeetingList: any;
  public meeting: any;

  constructor(public firestore: FirestoreService, public router: Router) {
      this.firestore.getMeeting().subscribe(x =>{
        this.MeetingList = x.docs.map(meetinglist =>{          
          this.meeting = meetinglist.data();
          let a = {
            'id': meetinglist.id,
            'title' : this.meeting.EventTitle,
            'type' : this.meeting.EventType,
            'subject' : this.meeting.EventSubject,
            'date': this.meeting.EventDate,
            'duration': this.meeting.EventDuration,
            'location' : this.meeting.EventLocation,
            'description' : this.meeting.EventDescription,
            'mom' : this.meeting.EventMinutes,
          }
          return a;
        });
      });
  }

  ngOnInit() {
  }

  onDisplayDetails(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    }
    this.router.navigate(['/meeting-details'], navigationExtras)
  }
  
}



