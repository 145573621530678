import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../shared/services/firestore.service';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-meetingdetails',
  templateUrl: './meetingdetails.component.html',
  styleUrls: ['./meetingdetails.component.css']
})
export class MeetingdetailsComponent implements OnInit {

  public selectedId : string = '';
  public MeetingDetails : any;
  public meeting: any;

  constructor(public firestore: FirestoreService, public router: Router, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.selectedId = params["id"];
      console.log(this.selectedId);
    });
    this.firestore.getMeeting().subscribe(x =>{
      this.MeetingDetails = x.docs.filter(meetingList =>{
        this.meeting = meetingList.data();
        if(meetingList.id == this.selectedId){
          console.log(this.meeting);
          return true;
        }
        else {
          return false
        };
      }).map(meetinglist =>{          
        this.meeting = meetinglist.data()
        let a = {
          'id': meetinglist.id,
            'title' : this.meeting.EventTitle,
            'type' : this.meeting.EventType,
            'subject' : this.meeting.EventSubject,
            'date': this.meeting.EventDate,
            'duration': this.meeting.EventDuration,
            'location' : this.meeting.EventLocation,
            'description' : this.meeting.EventDescription,
            'mom' : this.meeting.EventMinutes,
        }
        return a;
      });

      console.log(this.MeetingDetails);
      
    });
  }

  ngOnInit() {
  }

}

