import { Component, OnInit, ViewChild, Renderer2,ChangeDetectorRef  } from '@angular/core';
import { NgImageSliderComponent } from 'ng-image-slider';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html', 
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('nav', {static: false}) slider: NgImageSliderComponent;

  imageObject: Array<object> = [
    {
      image: 'assets/slider/Panchayat building.jpg',
      thumbImage: 'assets/slider/Panchayat building.jpg',
      alt: 'Cana Benaulim VP',
      title: 'Cana Benaulim Village Panchayat'
    },
    {
      image: 'assets/slider/collage.png',
      thumbImage: 'assets/slider/collage.png',
      alt: 'Dry Waste Management',
      title: 'We are working towards a cleaner and greener Benaulim, one step at a time.'
    },
    {
      image: 'assets/slider/benaulim_beach_main.jpg',
      thumbImage: 'assets/slider/benaulim_beach_main.jpg',
      alt: 'Benaulim',
      title: 'Benaulim'
    },
    {
      image: 'assets/slider/slider-bg3.jpg',
      thumbImage: 'assets/slider/slider-bg3.jpg',
      alt: 'Download applicaion Forms',
      title: 'Application Forms are Online'
    }
  ];
  public categories = [
    {
      name: 'International Coastal Cleanup Day',
      mainImage: '../../assets/images/events/1 International Coastal Cleanup Day/iccd 1.jpg',
      subImages: ['../../assets/images/events/1 International Coastal Cleanup Day/iccd 1.jpg', '../../assets/images/events/1 International Coastal Cleanup Day/iccd 2.jpg'],
      description: "The Village Panchayat Cana Benaulim celebrated International Coastal Clean- up Day on 17th September 2022"
    },
    {
      name: 'Rally',
      mainImage: '../../assets/images/events/2 Rally/03.jpg',
      subImages: ['../../assets/images/events/2 Rally/03.jpg'],
      description: "Village Panchayat Cana Benaulim along with the students of St. Aloysius High School conducted a rally towards no single use plastic in the jurisdiction of Benaulim on 27th September 2022."
    },
    {
      name: 'Wealth out of waste',
      mainImage: '../../assets/images/events/(3) Wealth out of waste/main.jpg',
      subImages: ['../../assets/images/events/(3) Wealth out of waste/main.jpg','../../assets/images/events/(3) Wealth out of waste/1.jpg' ],
      description: "Wealth out of waste competition held at St. Aloysius High School, Benaulim on 27th September 2022"
    },
    {
      name: 'Cleanliness drive',
      mainImage: '../../assets/images/events/(4) Cleanliness drive/01.jpg',
      subImages: ['../../assets/images/events/(4) Cleanliness drive/01.jpg','../../assets/images/events/(4) Cleanliness drive/02.jpg','../../assets/images/events/(4) Cleanliness drive/03.jpg', '../../assets/images/events/(4) Cleanliness drive/04.jpg',],
      description: "Village Panchayat Cana Benaulim along with the students of Auxilium Convent High School conducted a cleanliness drive on 1st October 2022 "
    },
    {
      name: 'Foundation Day',
      mainImage: '../../assets/images/events/(7) Foundation Day/1.jpg',
      subImages: ['../../assets/images/events/(7) Foundation Day/1.jpg', '../../assets/images/events/(7) Foundation Day/2.jpg', '../../assets/images/events/(7) Foundation Day/3.jpg', '../../assets/images/events/(7) Foundation Day/4.jpg'],
      description: "The Village Panchayat Cana Benaulim on the occasion of Village Foundation Day organised a Musical Fiesta and honoured the past Sarpanchas of Village Panchayat Cana Benaulim on 15 January, 2022"
    },
    {
      name: 'Republic Day',
      mainImage: '../../assets/images/events/(8) Republic Day/2.jpg',
      subImages: ['../../assets/images/events/(8) Republic Day/1.jpg', '../../assets/images/events/(8) Republic Day/2.jpg'],
      description: "The Village Panchayat Cana Benaulim celebrated the 74th Republic Day on 26th January 2023"
    },
    {
      name: 'Free Medical Camp',
      mainImage: '../../assets/images/events/(9) Free Medical Camp/1.jpg',
      subImages: ['../../assets/images/events/(9) Free Medical Camp/1.jpg', '../../assets/images/events/(9) Free Medical Camp/2.jpg', '../../assets/images/events/(9) Free Medical Camp/3.jpg', '../../assets/images/events/(9) Free Medical Camp/4.jpg'],
      description: "The Village Panchayat Cana Benaulim in association with PTA members of Auxilium High School, Benaulim organised Free Medical Camp on 26th January 2023."
    },
    // Add more categories as needed
  ];
  public selectedCategory: any;

  openCategoryModal(category: any): void {
    console.log("opened modal", category);
    this.selectedCategory = category;
    this.showModal();
  }

  closeCategoryModal(): void {
    this.selectedCategory = null;
    this.hideModal();
  }

  selectSubImage(subImage: string): void {
    this.selectedCategory.mainImage = subImage;
  }

  private showModal(): void {
    const modal = this.getModalElement();
    this.renderer.setStyle(modal, 'display', 'block');
  }

  private hideModal(): void {
    const modal = this.getModalElement();
    this.renderer.setStyle(modal, 'display', 'none');
  }

  private getModalElement(): HTMLElement {
    return document.querySelector('.modal') as HTMLElement;
  }
  constructor(private renderer: Renderer2,private cdr: ChangeDetectorRef) {

  }

  ngOnInit() { 
  }
  
}
