import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FormsAndCertificatesComponent } from './forms-and-certificates/forms-and-certificates.component';
import { HomeComponent } from './home/home.component';
import { GalleryComponent } from './gallery/gallery.component';
import { MeetingsComponent} from './meetings/meetings.component';
import { ActsComponent } from './acts/acts.component';
import { ProceedingsComponent } from './proceedings/proceedings.component';
import { MeetingdetailsComponent } from './meetings/meetingdetails/meetingdetails.component';
import { ProceedingdetailsComponent } from './proceedings/proceedingdetails/proceedingdetails.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'forms-and-certificates', component: FormsAndCertificatesComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'acts', component: ActsComponent },
    { path: 'meetings', component: MeetingsComponent },
    { path: 'proceedings', component: ProceedingsComponent },
    { path: 'meeting-details', component: MeetingdetailsComponent },
    { path: 'proceeding-details', component: ProceedingdetailsComponent },
];

@NgModule ({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule {


}