import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})

export class AboutUsComponent implements OnInit {

  members: Member[] = [
    new Member('Mr. Xavier Wenecy Pereira','V.P Sarpanch','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Glyda Fernandes','V.P Dy. Sarpanch','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Ezlina Valentina Fernandes','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Felicia Noronha','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Genciana Barreto Colaco','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mr. David F. Fernandes','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Diella Fernandes','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Vencilla Rodrigues','V.P Member', 'assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Resica Fernandes','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mr. Tukaram Kesarkar','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
    new Member('Mrs. Rini S. Mascarenhas','V.P Member','assets/images/members-and-staff/dummy-user.png'),//
  ];

  staffmembers: Staff[] = [
    new Staff('Mr. Shrirang Agrasani','V.P Secretary',''),
    new Staff('Mr. Francisco Fernandes','V.P Clerk',''),
    new Staff('Mrs. Maria Pereira','V.P Clerk',''),
    new Staff('Miss. Cina Fernandes','V.P Clerk',''),
    new Staff('Ms. Nadia Nisha Gomes','V.P Clerk',''),
    new Staff('Mr. Mr. Anyel Rodrigues','V.P Peon / Messenger',''),
    new Staff('Ms. Faxela Dsouza','V.P Clerk',''),
    
    
  ];

  constructor() { }

  ngOnInit() {
  }
}

export class Member {
  constructor (
    public name: string,
    public position: string,
    public imagePath: string,
    ) {}
}

export class Staff {
  constructor (
    public name: string,
    public position: string,
    public imagePath: string,
  ) {}
}

