import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forms-and-certificates',
  templateUrl: './forms-and-certificates.component.html',
  styleUrls: ['./forms-and-certificates.component.css']
})
export class FormsAndCertificatesComponent implements OnInit {

  certificates: Certificate[] = [
    new Certificate('Authority Letter (PCC)','Authority Letter (PCC)','lime'),
    new Certificate('Birth & Death Certificate','Birth & Death Application','forest'),
    new Certificate('Construction License','Construction License','green'),
    new Certificate('Divergency Certificate','Divergency Certificate','sky'),
    new Certificate('Transfer of House Tax','Transfer of House Tax','navy'),
    new Certificate('N.O.C. for Trade License','N.O.C. for Trade License','blue'),
    new Certificate('N.O.C. for V.P. Hall','N.O.C. for V.P. Hall','yellow'),
    new Certificate('N.O.C. for Water/Electricity','N.O.C. for Water & Electricity','pink'),
    new Certificate('NonAvailability Certificate','Non-Availability Certificate','orange'),
    new Certificate('Occupancy Certificate','Occupancy Certificate','red'),
    new Certificate('Income Certificate','Income Certificate with Self Declaration','lime'),
    new Certificate('Residential Certificate','Residence Certificate','brown'),
    new Certificate('Construction License (Renewal)','Renewal of Construction License','aqua')
  ];

  constructor() { }

  ngOnInit() {
  }

}

  export class Certificate {
    constructor (
      public name: string,
      public docname: string,
      public color: string,
      ) {}
  }
