import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../assets/css/color/blue.css']
})
export class HeaderComponent implements OnInit {
  isMobileMenuOpen = false;

  toggleMobileMenu() {
    
    
    // const navBarsElement = document.querySelector('.nav-bars-inner .nav-bars');
    // console.log(navBarsElement);
    // if (navBarsElement) {
    //   navBarsElement.classList.remove('is-active');
    // }
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    console.log(this.isMobileMenuOpen);
  }
  closeMenu (){
    
  }

  constructor() { }

  ngOnInit() {
  }

}
